import React from "react"

import {
  BoltIcon,
  ChartBarIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid"
import classNames from "classnames"
import { ChevronRight } from "lucide-react"
import { ITranslations } from "../../../interfaces/ITranslations"
import { FormattedMessage } from "../../general/FormattedMessage/FormattedMessage"
import { FoudroyerLink } from "../../general/FoudroyerLink/FoudroyerLink"

interface TemplateOption {
  id: string
  title: ITranslations["keys"]
  description: ITranslations["keys"]
  icon: React.ComponentType<{ className?: string }>
  color: "emerald" | "blue" | "pink"
  url: string
}

const templates: TemplateOption[] = [
  {
    id: "indexation",
    title: "dashboard/select-features/indexation",
    description: "dashboard/select-features/indexation/description",
    icon: BoltIcon,
    color: "pink",
    url: "/dashboard/indexation/",
  },
  {
    id: "analysis",
    title: "dashboard/select-features/show-off",
    description: "dashboard/select-features/show-off/description",
    icon: ChartBarIcon,
    color: "blue",
    url: "/dashboard/show-off/",
  },
  {
    id: "sitemap",
    title: "dashboard/select-features/sitemapy",
    description: "dashboard/select-features/sitemapy/description",
    icon: MagnifyingGlassIcon,
    color: "emerald",
    url: "/dashboard/sitemapy/",
  },
]

const getBgColor = (color: string) => {
  if (color === "pink") {
    return "bg-pink-50"
  }
  if (color === "blue") {
    return "bg-blue-50"
  }
  if (color === "emerald") {
    return "bg-emerald-50"
  }
}

const getBgHoverColor = (color: string) => {
  if (color === "pink") {
    return "hover:bg-pink-50"
  }

  if (color === "emerald") {
    return "hover:bg-emerald-50"
  }

  if (color === "blue") {
    return "hover:bg-blue-50"
  }
}

const getIconColor = (color: string) => {
  if (color === "pink") {
    return "text-pink-500"
  }
  if (color === "blue") {
    return "text-blue-500"
  }
  if (color === "emerald") {
    return "text-emerald-500"
  }
}

export const SelectFeatures = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="w-full max-w-3xl p-6 space-y-6">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-2xl font-semibold">
              <FormattedMessage id="dashboard/select-features/title" />
            </p>
            <p className="text-slate-500">
              <FormattedMessage id="dashboard/select-features/description" />
            </p>
          </div>
        </div>

        <div className="">
          {templates.map((template) => (
            <FoudroyerLink
              to={template.url}
              key={template.id}
              className={classNames(
                "cursor-pointer rounded-lg pr-4 py-2 transition-all flex justify-between items-center",
                getBgHoverColor(template.color)
              )}
            >
              <div className="flex items-center gap-4">
                <div
                  className={classNames(
                    "p-4 rounded-md",
                    getBgColor(template.color)
                  )}
                >
                  <template.icon
                    className={classNames(
                      "w-6 h-6",
                      getIconColor(template.color)
                    )}
                  />
                </div>

                <div className="flex-1">
                  <p className="font-medium">
                    <FormattedMessage id={template.title} />
                  </p>
                  <p className="text-sm text-slate-500">
                    <FormattedMessage id={template.description} />
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-2">
                <ChevronRight className="w-4 h-4" />
              </div>
            </FoudroyerLink>
          ))}
        </div>
      </div>
    </div>
  )
}
