import { RouteComponentProps } from "@reach/router"
import React from "react"
import { useIntl } from "react-intl"
import { SelectFeatures } from "../components/dashboard/SelectFeatures/SelectFeatures"
import { ConfirmModal } from "../components/general/ConfirmModal/ConfirmModal"
import { CreateWebsiteModal } from "../components/general/CreateWebsiteModal/CreateWebsiteModal"
import { Navbar } from "../components/general/Navbar/Navbar"
import { Notifications } from "../components/general/Notifications/Notifications"
import { Protected } from "../components/general/Protected/Protected"
import { Seo } from "../components/general/Seo/Seo"
import { IndexationWebsiteBadSetupOnGoogleModal } from "../components/indexation-website-bad-setup-on-google-modal/indexation-website-bad-setup-on-google-modal"
import { IndexationWebsiteBadSetupOnGoogleSuccessModal } from "../components/indexation-website-bad-setup-on-google-success-modal/indexation-website-bad-setup-on-google-success-modal"
import { GoogleKeysModal } from "../components/indexation/GoogleKeysModal/GoogleKeysModal"
import { IndexationActivateSearchEngineModalModal } from "../components/indexation/IndexationActivateSearchEngineModal/IndexationActivateSearchEngineModal"
import { IndexationAutoSettingsModal } from "../components/indexation/IndexationAutoSettingsModal/IndexationAutoSettingsModal"
import { IndexNowCheckModal } from "../components/indexation/IndexMeNowCheckModal/IndexNowCheckModal"
import { UpdateSitemapModal } from "../components/indexation/UpdateSitemapModal/UpdateSitemapModal"
import { Footer } from "../components/marketing/Footer"
import { PremiumModal } from "../components/payment/UpsellConfirmationModal/UpsellConfirmationModal"
import { AddUserToWebsiteModal } from "../components/settings/AddUserToWebsiteModal/AddUserToWebsiteModal"
import { Container } from "../components/UI/Container"

export const DashboardRoute: React.FC<RouteComponentProps> = () => {
  const intl = useIntl()
  return (
    <Protected>
      <Seo
        title={intl.formatMessage({
          id: `seo/opportunities/title`,
        })}
        description={intl.formatMessage({
          id: `seo/opportunities/description`,
        })}
        lang="en"
        langUrls={[
          {
            lang: "en",
            url: "/opportunities",
            isDefault: true,
          },
        ]}
      />

      <div className="relative min-h-screen">
        <Navbar />

        <Container>
          <div className="mt-4" />
          <SelectFeatures />
        </Container>
      </div>

      <IndexationWebsiteBadSetupOnGoogleSuccessModal />
      <IndexationWebsiteBadSetupOnGoogleModal />
      <AddUserToWebsiteModal />
      <CreateWebsiteModal />
      <IndexNowCheckModal />
      <GoogleKeysModal />
      <IndexationActivateSearchEngineModalModal />
      <PremiumModal />
      <IndexationAutoSettingsModal />
      <Footer />
      <Notifications />
      <UpdateSitemapModal />
      <ConfirmModal />
    </Protected>
  )
}
